import st from "./NewsLetterSection.module.scss";

const NewsLetterSection = () => {

    return (
        <div className={st.section_container}>
            <div className={st.content_container}>
                <div className={st.title_container}>
                    <span className={st.title}>
                        영화 정보 뉴스레터 신청
                    </span>
                    <span className={st.description}>
                        영화 정보 뉴스레터는 구독자들에게<br className={st.line_break}/> 최신 영화 소식 및 리뷰를 전달해드립니다.
                    </span>
                </div>
                <div className={st.input_container}>
                    <input type="text" placeholder="Your email address"/>
                    <button onClick={() => {alert('준비중입니다.')}}>신청하기</button>
                </div>
            </div>
        </div>
    )
}

export default NewsLetterSection;