import { RefObject } from "react";

import st from "./Introduction.module.scss";

const Introduction = ({ targetRef } : { targetRef : RefObject<HTMLDivElement[]> }) => {
    
    return (
        <div className={st.section_container} ref={(el : HTMLDivElement) => (targetRef?.current?.fill(el, 0, 1))}>
            <div className={st.text_container} data-aos="fade-up">
                <span className={st.main_title}>
                    영화의 데이터를<br className={st.line_break}/> AI로 담다!
                </span>
                <span className={st.content}>
                    영화 속 숨겨진 이야기를<br className={st.line_break}/> 다양한 데이터로 풀어내다
                </span>
            </div>
        </div>
    )
}

export default Introduction;