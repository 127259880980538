import { MovieAICardProps } from "types/main/movieAIProps";
import AnalysisButton from "pages/main/tableprops/AnalysisButton";

import st from "./AICard.module.scss";

interface AICardProps {
  data: MovieAICardProps;
  onOpenModal: (cardData: MovieAICardProps) => void;
}

const AICard = ({ data, onOpenModal }: AICardProps) => {
  return (
    <div className={st.card_container}>
      <div className={st.contents}>
        <div className={st.aidata}>
          <span className={st.movie_title}>{data?.movie}</span>
          <div className={st.gpt_predict}>
            <span>chatGPT 예상</span>
            <div>
              <b>최저 흥행 예상 : </b>
              {data?.lowestWatchedCount}
            </div>
            <div>
              <b>평균 흥행 예상 : </b>
              {data?.middleWatchedCount}
            </div>
            <div>
              <b>최고 흥행 예상 : </b>
              {data?.highestWatchedCount}
            </div>
          </div>
          <div className={st.gpt_analysis}>
            <span>chatGPT 주요분석</span>
            <p>{data?.predictiveAnswer}</p>
          </div>
          <div className={st.button} onClick={() => onOpenModal(data)}>
            <AnalysisButton hoverEffect={false} textColor="#FFFFFF" width={167}/>
          </div>
        </div>
        <div className={st.default}>
          <div className={st.default_title}>
            <span>{data?.title}</span>
            <p>
              <b>개봉일</b> {data?.openDate}
            </p>
          </div>
          <img className={st.image} src={data?.imageUrl} alt="poster" />
        </div>
      </div>
    </div>
  );
};

export default AICard;
