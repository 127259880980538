import { useEffect, useState } from "react";

import CustomTable from "components/common/CustomTable";
import Analysis from "../tableprops/AnalysisButton";
import MovieTitle from "../tableprops/MovieTitle";
import useMovieTop10Store from "stores/main/useMovieTop10Store";
import modifyTop10Data from "utils/modifyMovieData";

import st from "./OnlineSection.module.scss";
import MobileTable from "components/main/MobileTable";

const OnlineSection = () => {
  const [tableWidth, setTableWidth] = useState(1320);
  const { movieDatas } = useMovieTop10Store();
  const modifiedMovieDatas = movieDatas?.map((val) => {return modifyTop10Data(val)})
  
  const defaultColumns = [
    {
      key: 'title',
      label: '영화명',
      isCustomCell: true,
      align: "left",
      fixed: true,
      flexGrow: window.innerWidth >= 1440 ? 2 : 1
    },
    {
      key: 'country',
      label: '국가',
      align: "center",
      flexGrow: 1
    },
    {
      key: 'ticketingPercent',
      label: '예매율',
      align: "center",
      flexGrow: 1
    },
    {
      key: 'watchedTotalCount',
      label: '관객수',
      align: "center",
      flexGrow: 1
    },
    {
      key: 'movieBuzzCount',
      label: '전체버즈량',
      align: "center",
      flexGrow: 1
    },
    {
      key: 'exposure',
      label: '노출도',
      align: "center",
      flexGrow: 1
    },
    {
      key: 'responsiveness',
      label: '반응도',
      align: "center",
      flexGrow: 1
    },
    {
      key: 'dataAnalysis',
      label: '데이터분석',
      isCustomCell: true,
      align: "center",
      flexGrow: 1
    }
  ];

  const handleResize = () => {
    const width = window.innerWidth;
    
    if (width <= 1400) {
      setTableWidth(1000);
    }
    else {
      setTableWidth(1320);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    
    return () => window.removeEventListener("resize", handleResize);
  }, [tableWidth]);

  return (
    <div className={st.section_container}>
        <div className={st.title} data-aos="fade-up">
            <span>ONLINE DATA ANALYSIS</span>
            <p>온라인데이터 분석</p>
        </div>
        { window.innerWidth > 1024 &&
          <div className={st.table} data-aos="fade-up">
            <CustomTable 
              rowKey={"grade"}
              columns={defaultColumns} 
              datas={modifiedMovieDatas?.slice(0,5)}
              width={tableWidth}
            >
              <MovieTitle key={"title"} text="" id={1}/>
              <Analysis backColor="#F2F2F2" hoverEffect key={"dataAnalysis"}/>
            </CustomTable>
          </div>
        } 
        { window.innerWidth <= 1024 &&
          <div data-aos="fade-up">
            <MobileTable
              datas={modifiedMovieDatas?.slice(0,5)}
            />
          </div>
        }   
    </div>
  )
}

export default OnlineSection;