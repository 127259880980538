import { ReactComponent as Cancel } from "assets/svg/cancel.svg";
import SubscriptionOnly from "components/analysisModal/SubscriptionOnly";
import { forwardRef, useEffect } from "react";
import useModalStore from "stores/modal/useModalStore";
import { MovieAICardProps } from "types/main/movieAIProps";
import TopTenAnalysis from "../../components/analysisModal/TopTenAnalysis";

import st from "pages/modal/AnalysisModal.module.scss";

interface AnalysisModalProps {
  data: MovieAICardProps | null;
  onClose: () => void;
}

const AnalysisModal = forwardRef<HTMLDialogElement, AnalysisModalProps>(
  ({ data, onClose }, ref) => {
    const { selectedCardData } = useModalStore();

    useEffect(() => {
      if (ref && typeof ref !== "function" && ref.current) {
        document.body.style.overflow = "hidden";

        return () => {
          document.body.style.overflow = "auto";
        };
      }
      return;
    }, [ref]);

    return (
      <dialog className={st.dialog} ref={ref}>
        <div className={st.content}>
          <div className={st.between}>
            <p>
              MOVIEBOARD
              <span className={st.next}>by Sociallouder</span>
            </p>
            <Cancel className={st.cancle_btn} onClick={onClose} />
          </div>
          {selectedCardData && (
            <TopTenAnalysis initialSelectedMovie={selectedCardData} />
          )}
          <SubscriptionOnly />
        </div>
      </dialog>
    );
  }
);

export default AnalysisModal;
