import img1 from "../../../assets/svg/movieSlate.svg";
import img2 from "../../../assets/svg/monitor.svg";
import img3 from "../../../assets/svg/target.svg";
import img4 from "../../../assets/svg/aiWithHands.svg";

import st from "./DataAnalyzeSection.module.scss";

const DataAnalyzeSection = () => {
    const buttonList = [
        {
            imgpath : img1,
            label : "우리영화 분석",
            description : "Labore proident nisi fugiat nostrud sint mollit aliqua ipsum ad veniam cupidatat"
        },
        {
            imgpath : img2,
            label : "전체버즈량 분석",
            description : "Labore proident nisi fugiat nostrud sint mollit aliqua ipsum ad veniam cupidatat ullamco ulla"
        },
        {
            imgpath : img3,
            label : "경쟁영화 분석",
            description : "Labore proident nisi fugiat nostrud sint mollit aliqua ipsum ad veniam cupidatat ullamco ulla"
        },
        {
            imgpath : img4,
            label : "AI예측분석",
            description : "Labore proident nisi fugiat nostrud sint mollit aliqua ipsum ad veniam cupidatat"
        }
    ];

    return (
        <div className={st.section_container}>
            <div className={st.content_container} data-aos="fade-right">
                <span className={st.title}>
                    <span>ONLINE</span>
                    <span>DATA</span>
                    <span>ANALYZE</span>
                </span>
                <div className={st.button_container}>
                    {
                        buttonList.map((item, key) => {
                            return (
                                <div key={key} data-aos="fade-down">
                                    <img src={item.imgpath} alt="online_image" />
                                    <span>{item?.label}</span>
                                    <p>{item?.description}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default DataAnalyzeSection;