import graph from "assets/image/graph.png";
import { ReactComponent as Crown } from "assets/svg/crown.svg";
import AIMarketing from "./AIMarketing";
import OnlineStatistics from "./DataStatistics";
import ViralStatistics from "./ViralStatistics";

import st from "components/analysisModal/SubscriptionOnly.module.scss";

const SubscriptionOnly = () => {
  const statisticDatas = [
    {
      sns: "P",
      title: "포털데이터(블로그/카페)",
      amount: 500000,
      percentage: "83%",
      bgColor: "#CB70CA",
    },
    {
      sns: "C",
      title: "커뮤니티(TOP5)",
      amount: 100000,
      percentage: "50%",
      bgColor: "#8878DA",
    },
    {
      sns: "N",
      title: "NEWS(온라인)",
      amount: 100000,
      percentage: "66%",
      bgColor: "#84BADA ",
    },
  ];

  return (
    <div className={st.section}>
      <h2>
        <Crown className={st.crown_img} />
        구독전용
      </h2>
      <h3>온라인데이터 통계</h3>
      <div className={st.graph}>
        <img src={graph} alt="그래프 이미지" />
      </div>
      <div className={st.boxes}>
        {statisticDatas.map((data, idx) => (
          <OnlineStatistics
            key={idx}
            sns={data.sns}
            title={data.title}
            amount={data.amount.toLocaleString()}
            percentage={data.percentage}
            bgColor={data.bgColor}
          />
        ))}
      </div>
      <ViralStatistics />
      <AIMarketing />
    </div>
  );
};

export default SubscriptionOnly;
