import statisticGraph from "assets/image/statisticGraph.png";

import st from "components/analysisModal/AIRecommend.module.scss";

interface Props {
  title: string;
}

const AIRecommend = ({ title }: Props) => {
  return (
    <div className={st.box}>
      <div>
        <img src={statisticGraph} alt="그래프 이미지" />
        <div className={st.between}>
          <span>
            <div className={st.blue_circle}></div>긍정적반응 72%
          </span>
          <span>
            <div className={st.lilac_circle}></div>부정적반응 28%
          </span>
        </div>
      </div>
      <div className={st.desc}>
        <h3>{title}</h3>
        <p>
          1971년 겨울 속초공항 여객기 조종사 태인(하정우)과 규식(성동일)은
          김포행 비행에 나선다. 승무원 옥순(채수빈)의 안내에 따라 탑승 중인
          승객들의 분주함도 잠시, 이륙한지 얼마 되지 않아 사제폭탄이 터지며
          기내는 아수라장이 된다. "지금부터 이 비행기 이북 간다" 여객기를 통째로
          납치하려는 용대(여진구)는 조종실을 장악하고 ... 1971년 겨울 속초공항
          여객기 조종사 태인(하정우)과 규식(성동일)은 김포행 비행에 나선다.
          승무원 옥순(채수빈)의 안내에 따라 탑승 중인 승객들의 분주함도 잠시,
          이륙한지 얼마 되지 않아 사제폭탄이 터지며 기내는 아수라장이 된다.
          "지금부터 이 비행기 이북 간다" 여객기를 통째로 납치하려는
          용대(여진구)는 조종실을 장악하고 ...
        </p>
      </div>
    </div>
  );
};

export default AIRecommend;
