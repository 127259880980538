import { useState } from 'react';
import { ReactComponent as ChartIcon } from '../../../assets/svg/lineChart.svg';

import st from "./AnalysisButton.module.scss";

interface AnalysisButtonProps {
  width? : number | 'inherit' | '100%',
  height? : number | 'inherit' | '100%',
  backColor? : string,
  borderColor? : string,
  textColor? : string,
  hoverEffect? : boolean,
  handleClick? : () => void
};

const AnalysisButton = ({ width = 'inherit', height = 'inherit', backColor, borderColor, textColor, hoverEffect = true, handleClick } : AnalysisButtonProps) => {
  const [isHover, setIsHover] = useState<boolean>(false);

  const handleStyle = (size : number | 'inherit' | '100%') => {
    let buffer : string;
    buffer = size.toString();

    if (typeof size === 'number') {
      buffer = size + 'px';
    }  

    return buffer;
  }

  const handleHover = () => {
    if (hoverEffect){
      setIsHover(true);
    } else {
      setIsHover(false);
    }
  }

  const handleNotHover = () => {
    setIsHover(false);
  }

  const hexToRgba = (hex: string, alpha: number = 1) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
  
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  return (
    <div className={st.button_container}
      style={{
        width: handleStyle(width),
        height: handleStyle(height)
      }}
    >
      <button 
        className={hoverEffect ? st.button_hover : st.button_activated}
        style={
          hoverEffect ?
          {
          border: borderColor ? `1px solid ` + hexToRgba(borderColor) : 'transparent',
        } : {
          border: borderColor ? `1px solid ` + hexToRgba(borderColor) : 'transparent',
          background: backColor ? hexToRgba(backColor) : `linear-gradient(279deg, #fb6714 9.27%, #d68f41 95.19%)`
        }}
        onMouseOver={handleHover}
        onMouseOut={handleNotHover}
        onClick={handleClick}
      >
        <span 
          style={isHover ? 
            {
              color: "white"
            }
            :
            {
              color: textColor ? hexToRgba(textColor) : "#111111",
            }
          }
        >데이터분석</span>
        <ChartIcon 
          style={isHover ? 
            {
              width: 20,
              fill: "white"
            }
            :
            {
              width: 20,
              fill: textColor ? hexToRgba(textColor) : "black"
            }
          }
        />
      </button>
    </div>
  )
}

export default AnalysisButton;