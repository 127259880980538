import React, { ReactElement, ReactNode } from 'react';
import { Table } from "rsuite";
import { ColumnStyleSetting, TableColumnValueProps } from 'types/common/customTable';

import "./CustomTable.css";

const { Column, HeaderCell, Cell } = Table;
const CompactCell = (props : any) => <Cell {...props} style={{ padding: 4 }} />;
const CompactHeaderCell = (props : any) => <HeaderCell {...props} style={{ display: "flex", alignItems: "center" }} />;

const getCustomCell = (children: ReactNode, key: string) => {
  return React.Children.toArray(children).find(
    (child) => React.isValidElement(child) && child.key === `.$${key}`
  );
};

const getCloneElement = (
  customCell: ReactElement,
  text: string,
  id: string
) => {
  return React.cloneElement(customCell, { text, id });
};


const CustomTable = ({
    datas,
    columns,
    children,
    rowKey,
    type,
    width = 1320,
    height = 420
  }: TableColumnValueProps) => {
    
    return (
        <div className="table_container" data-aos="fade-up">
          <Table
              width={width}
              height={height}
              data={datas ? datas : []}
              hover={true}
              headerHeight={70}
              rowHeight={70}
              rowKey={rowKey}
          >
            {columns.map(column => {
              const { key, label, isCustomCell, ...rest } = column;

              const columnSettings: ColumnStyleSetting = {
                verticalAlign: "middle",
                align: rest.align ? rest.align : "center",
                ...rest,
              };
          
              if (isCustomCell) {
                return (
                  <Column key={column.key} {...columnSettings}>
                    <CompactHeaderCell>{label}</CompactHeaderCell>
                    <Cell dataKey={key}>
                      {(data) => {
                        return (
                          <div>
                            {getCloneElement(
                              getCustomCell(children, key) as ReactElement,
                                data[key],
                                data[rowKey]
                              )}
                          </div>
                        )
                      }}
                    </Cell>
                  </Column>
                );
              }
              else {
                return (
                  <Column {...rest} key={column.key}>
                    <CompactHeaderCell>{label}</CompactHeaderCell>
                    <CompactCell dataKey={key} />
                  </Column>
                );
              }

            })}
          </Table>
        </div>
    )
}

export default CustomTable;