import AIRecommend from "./AIRecommend";

import st from "components/analysisModal/AIMarketing.module.scss";

const AIMarketing = () => {
  return (
    <div className={st.section}>
      <h3 className={st.title}>Ai 마케팅 분석 및 업무 추천</h3>
      <AIRecommend title={"마케팅분석"} />
      <AIRecommend title={"리스크분석"} />
      <AIRecommend title={"경쟁영화대비"} />
    </div>
  );
};

export default AIMarketing;
