import barChart from "assets/image/barChart.png";
import { ReactComponent as Instagram } from "assets/svg/instagram.svg";
import { ReactComponent as Twitter } from "assets/svg/twitter.svg";
import { ReactComponent as Youtube } from "assets/svg/youtube.svg";
import OnlineStatistics from "./DataStatistics";

import st from "components/analysisModal/ViralStatistics.module.scss";

const ViralStatistics = () => {
  const statisticDatas = [
    {
      sns: <Instagram />,
      title: "인스타그램",
      amount: 500000,
      percentage: "83%",
    },
    {
      sns: <Twitter />,
      title: "트위터",
      amount: 100000,
      percentage: "50%",
    },
    {
      sns: <Youtube />,
      title: "유튜브",
      amount: 100000,
      percentage: "66%",
    },
  ];

  return (
    <div className={st.section}>
      <h3>바이럴데이터 통계</h3>
      <div className={st.graph}>
        <img src={barChart} alt="그래프 이미지" />
      </div>
      <div className={st.boxes}>
        {statisticDatas.map((data, idx) => (
          <OnlineStatistics
            key={idx}
            sns={data.sns}
            title={data.title}
            amount={data.amount.toLocaleString()}
            percentage={data.percentage}
          />
        ))}
      </div>
    </div>
  );
};

export default ViralStatistics;
