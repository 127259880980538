import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar } from "swiper/modules";
import SwiperCore from 'swiper';
import { RefObject, useEffect, useState } from "react";
import Marquee from "react-fast-marquee";

import { ZineCardProps } from "types/main/zineCardProps";
import ZineMovieCard from "components/main/ZineMovieCard";
import useGetApi from "api/useGetApi";

import 'swiper/scss';
import 'swiper/scss/scrollbar';
import st from "./ZineSection.module.scss";

const ZineSection = ({ targetRef } : { targetRef : RefObject<HTMLDivElement[]> }) => {
    const [slidesPerView, setSlidesPerView] = useState<number>(4);
    const [width, setWidth] = useState<number>(0);
    const [focused, setFocused] = useState<SwiperCore>();
    const [id, setId] = useState(0);
    const { data } = useGetApi("zinesection", "/movie-top-ten/movie-zine");
    
    const isOverflowScreenSize = () => {
        return width >= 1600;
    }

    const handleResize = () => {
        setWidth(window.innerWidth);

        switch (true) {
          case width >= 1024 :
            setSlidesPerView(4);
            break;

        case width >= 768 :
            setSlidesPerView(3);
            break;
          
        default :
            setSlidesPerView(2);
            break;
        }
      };

    const handleSwipe = (id : number) => {
        setId(id);
    }

    const handleOverflow = (text : string) => {
        return text.length >= 8;
    }

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
        
        return () => window.removeEventListener("resize", handleResize);
      }, [width]);

    return (
        <div className={st.section_container} ref={(el : HTMLDivElement) => (targetRef?.current?.fill(el, 2, 3))}>
            <div className={st.content_container}>
                <div className={st.title} data-aos="fade-left">
                    <span>
                        <b>7월</b>
                        <b>무비zine</b>
                    </span>
                    <div className={st.buttons} data-aos="fade-left">
                        {isOverflowScreenSize() && 
                        
                        data?.map((item : ZineCardProps, index : number) => {
                                const overflow = handleOverflow(item.movie)
                                
                                return (
                                        <button key={item.idx - 1} onClick={() => {focused?.slideTo(item.idx - 1)}} className={id === item.idx - 1 ? st.button_active : st.button_default}>
                                            <div className={st.bar}/>
                                            <span className={overflow ? st.movie_name_overflow : st.movie_name_default}>
                                                <p>{item.movie}</p>
                                                { overflow && 
                                                <Marquee className={st.marquee} delay={1}>
                                                    {item.movie}
                                                </Marquee>
                                                }
                                            </span>
                                        </button>
                                )
                            })}
                        { !isOverflowScreenSize() &&
                        <Swiper
                            spaceBetween={40}
                            autoHeight={true}
                            slidesPerView={slidesPerView}
                            centeredSlides={false}
                            direction={"horizontal"}
                        >
                            {data?.map((item : ZineCardProps, index : number) => {
                                const overflow = handleOverflow(item.movie)
                                
                                return (
                                    <SwiperSlide key={index}>
                                        <button key={item.idx - 1} onClick={() => {focused?.slideTo(item.idx - 1)}} className={id === item.idx - 1 ? st.button_active : st.button_default}>
                                            <div className={st.bar}/>
                                            <span className={overflow ? st.movie_name_overflow : st.movie_name_default}>
                                                <p>{item.movie}</p>
                                                { overflow && 
                                                <Marquee className={st.marquee} delay={1}>
                                                    {item.movie}
                                                </Marquee>
                                                }
                                            </span>
                                        </button>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                        }
                    </div>
                </div>
                <div className={st.swiper_container_zine} data-aos="fade-up">
                    <Swiper
                        modules={[Scrollbar]}
                        spaceBetween={200}
                        autoHeight={true}
                        slidesPerView={1}
                        centeredSlides={true}
                        onSwiper={setFocused}
                        onSlideChange={(swiper : SwiperCore) => handleSwipe(swiper.activeIndex)}
                        scrollbar={{
                            draggable: true
                        }}
                    >
                        {data?.map ((item : ZineCardProps, key : number) => {
                            return (
                                <SwiperSlide key={key}>
                                    <ZineMovieCard data={item}/>
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                </div>
            </div>
        </div>
    )
}

export default ZineSection;