import { useEffect, useRef, useState } from "react";
import AOS from "aos";

import Footer from "layout/footer/Footer";
import NavBar, { NavBarElement } from "layout/navbar/NavBar";
import AISection from "./sections/AISection";
import DataAnalyzeSection from "./sections/DataAnalyzeSection";
import EventSection from "./sections/EventSection";
import Introduction from "./sections/Introduction";
import NewsLetterSection from "./sections/NewsLetterSection";
import OnlineSection from "./sections/OnlineSection";
import PlanSection from "./sections/PlanSection";
import ZineSection from "./sections/ZineSection";
import AnalysisModal from "pages/modal/AnalysisModal";
import useModalStore from "stores/modal/useModalStore";
import { closeModal } from "utils/closeModal";

import "aos/dist/aos.css";
import st from "./MainPage.module.scss";

const MainPage = () => {
  const [mode, setMode] = useState<"light" | "dark">("light");
  const [notice, setNotice] = useState<boolean>(true);
  const [scroll, setScroll] = useState<number>(window.scrollY);
  const [width, setWidth] = useState<number>(window.innerWidth);
  const { isModalOpen, selectedCardData, setIsModalOpen, setSelectedCardData } =
    useModalStore();

  const modalRef = useRef<HTMLDialogElement>(null);
  const targetRef = useRef<HTMLDivElement[]>(new Array(4));
  const [navList, setNavList] = useState<NavBarElement[]>();

  const handleCloseModal = () => {
    setIsModalOpen(false);
    if (modalRef.current) {
      closeModal(modalRef);
    }
    setSelectedCardData(null);
  };

  const changeNavBarMode = () => {
    setWidth(window.innerWidth);
    setScroll(window.scrollY);

    if (width <= 768) {
      setMode("dark");
    } else {
      if (scroll > 1000) {
        setMode("dark");
      } else {
        setMode("light");
      }
    }
  };

  const handleNotice = () => {
    setWidth(window.innerWidth);

    if (width <= 768) {
      setNotice(false);
    } else {
      setNotice(true);
    }
  };

  useEffect(() => {
    changeNavBarMode();
    handleNotice();
    window.addEventListener("scroll", changeNavBarMode);
    window.addEventListener("resize", handleNotice);

    return () => {
      window.removeEventListener("scroll", changeNavBarMode);
      window.removeEventListener("resize", handleNotice);
    };
  }, [scroll, width]);

  useEffect(() => {
    AOS.init();
    setNavList([
      {
        path: "movieboard",
        label: "무비보드",
        ref: targetRef?.current[0],
      },
      {
        path: "ai",
        label: "AI데이터",
        ref: targetRef?.current[1],
      },
      {
        path: "zine",
        label: "무비zine",
        ref: targetRef?.current[2],
      },
      {
        path: "service",
        label: "서비스안내",
        ref: targetRef?.current[3],
      },
    ]);
  }, []);

  return (
    <>
      <NavBar hasNotice={notice} mode={mode} list={navList} />
      <Introduction targetRef={targetRef} />
      <AISection targetRef={targetRef} />
      <OnlineSection />
      {isModalOpen && (
        <AnalysisModal
          ref={modalRef}
          data={selectedCardData}
          onClose={handleCloseModal}
        />
      )}
      <ZineSection targetRef={targetRef} />
      <DataAnalyzeSection />
      <PlanSection targetRef={targetRef} />
      <EventSection />
      <NewsLetterSection />
      <Footer />
    </>
  );
};

export default MainPage;
