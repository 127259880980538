import { OnlineDatas } from "types/modal/onlineStatistics";

import st from "components/analysisModal/DataStatistics.module.scss";

const DataStatistics = ({
  sns,
  title,
  amount,
  percentage,
  bgColor,
}: OnlineDatas) => {
  return (
    <div className={st.box}>
      <div>
        <span className={st.circle} style={{ backgroundColor: bgColor }}>
          {sns}
        </span>
        <span className={st.data}>
          {title}
          <span>{amount}</span>
        </span>
      </div>
      <div className={st.between}>
        <p>전체</p>
        <p>{percentage}</p>
      </div>
    </div>
  );
};

export default DataStatistics;
