import { ReactComponent as LogoLight } from "../../assets/svg/movieboardLogo.svg";
import { ReactComponent as LogoDark } from "../../assets/svg/movieboardLogoDark.svg";

import st from "./Logo.module.scss";

interface LogoProps {
    mode?: "light" | "dark"
};

const Logo = ({mode = "light"} : LogoProps) => {
    
    return (
        <div className={st.logo}>
            {
                mode === "light" &&
                <LogoLight/>
            }
            {
                mode === "dark" &&
                <LogoDark/>
            }
        </div>
    )
}

export default Logo;